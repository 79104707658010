import React from "react";
import {isMobile} from "../../util/util";
import {Link} from "react-router-dom";
class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        if(this.props.showMenu) {
            let menuSelection = isMobile() ? this.getMenuSelection().filter(x => x.mobile) : this.getMenuSelection();
            let menuElements = []
            for(let i in menuSelection) {
                menuElements.push(
                    <div className={'m-banner-menu-item'} key={menuSelection[i].name}>
                        <a href={menuSelection[i].endpoint}>
                            <label>{menuSelection[i].name}</label>
                        </a>
                    </div>
                );
            }
            return (
                <div className={'m-burger-menu'}>
                    {menuElements}
                </div>
            )
        }
    }

    getMenuSelection() {
        return [
            {name: 'Login' , mobile: true, endpoint: '/login'},
            {name: 'Register' , mobile: true, endpoint: '/register'},
            {name: 'Articles' , mobile: true, endpoint: '/articles'},
            {name: 'Predictions' , mobile: true, endpoint: '/articles/predictions'},
            {name: 'Stats' , mobile: true, endpoint: '/stats'},
            {name: 'About' , mobile: true, endpoint: '/about'},
            {name: 'Contact' , mobile: true, endpoint: '/about'},
        ]
    }
}

export default MobileMenu;