import {React, Component} from "react";
import {withRouter} from "../../util/withRouter";
import {Link} from "react-router-dom";

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleId: props.children.articleId
        }
    }
    render() {
        const url = `/article/${this.state.articleId}`;
        return (
            <div className={'post'}>
                <Link to={url}>
                    <div className={'post-header'}><label>{this.props.children.article.name}</label></div>
                    {/*<div className={'post-image-outer'}>*/}
                        <div className={'post-image'}>
                            <img
                                src={this.props.children.article.image}
                                width={'100%'}
                                height={'100%'}
                            />
                        </div>
                    {/*</div>*/}
                    {/*<div className={'post-text-outer'}>*/}
                        <div className={'post-text'}>
                            <p>
                                {this.props.children.article.preText}
                            </p>
                        </div>
                    {/*</div>*/}
                </Link>
            </div>

        )
    }
}

export default withRouter(Post);