import {React, Component} from "react";
import {redirect} from "../../util/util";
import SessionService from "../../service/sessionService";
import {isMobile} from "../../util/util";
import MobileMenu from "./MobileMenu";
class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            sessionService: SessionService.instance()
        }
    }

    render() {
        return (
            <div className={'c banner'}>
                <div className={'h-banner color-1'}>
                    <div className={'lc-banner'}>
                        <a href={'/home'}>
                            {/*<label className={'banner-title'}>$HOT BRAWLERS</label>    */}
                            <div className={'banner-logo-block'}>
                                <img height={'100%:'} width={'100%'}  src={'/shotbrawlers-logo-transparent.png'}/>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={'h-banner color-2'}>
                    {/*<div className={'banner-angle'}></div>*/}
                    <div className={'rc-banner'}>
                        {this.displayButtonSection()}
                    </div>
                </div>
                <MobileMenu showMenu={this.state.showMenu}></MobileMenu>
            </div>
        )
    }
    displayButtonSection() {
        return (
            <div className={'h100p'}>
                <div style={{marginRight: '50px'}}>
                    {this.showButtons()}
                </div>
            </div>
        )
    }
    showButtons() {
            return (
                <div style={{'line-height': '60px'}}>
                    <button className={'m-banner-btn'} onClick={this.showMobileMenu.bind(this)}>
                        MENU
                    </button>
                </div>
            )
    }

    logout() {
        this.state.sessionService.logout().then(result => {
            alert('Logged out successfully');
            setTimeout(() => redirect('/home'), 500);
        }).catch(err => {
            alert('Failed to logout!');
        });

    }

    showMobileMenu() {
        this.setState({showMenu: !this.state.showMenu});
    }
}

export default Banner;