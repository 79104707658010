export default class ArticleModel {
    name;
    image;
    preText;
    articleText;
    constructor(name, img, preText, articleText) {
        this.name = name;
        this.image = img;
        this.preText = preText;
        this.articleText = articleText;
    }
}