import {React, Component} from "react";
import MockApi from "../../mock/mockApi";
import {withRouter} from "../../util/withRouter";
import ApiService from "../../service/apiService";
import dayjs from "dayjs";
import SessionService from "../../service/sessionService";
class Article extends Component {


    constructor(props) {
        super(props);
        this.state = {
            articleId: -1,
            commentText: '',
            sessionService: SessionService.instance(),
            userId: '',
            article: null,
            comments: []
        }
        this.api = new MockApi();
    }

    componentDidMount() {
        setTimeout(() => {
            ApiService.instance().getArticle(this.props.router.params.id).then(result => {
                if(result && result.length && result.length > 0) {
                    const article = result[0].article;
                    this.setState({
                        article: article,
                        comments: result[0].comments,
                        articleId: result[0].articleId
                    });
                }
            });
        }, 500);
    }

    render = () => {
        const commentButtonSection = this.state.sessionService.isLoggedIn() && (
            <div className={'article-create-comment'}>
                <div className={'article-comment-left'}>
                                    <textarea
                                        type={'text'}
                                        id={'comment'}
                                        // onChange={this.updateCommentText.bind(this)}
                                    />
                </div>
                <div className={'article-comment-right'}>
                    <button id={'post-comment'}
                        // onChange={}
                            onClick={this.postComment.bind(this)}>
                        Comment
                    </button>

                </div>
            </div>
        );

        if(this.state.article) {
            return (
                <div className={'w100 article'}>
                    <div className={'article-container'}>
                        <div className={'article-title'}>
                            <h2>{this.state.article.name}</h2>
                        </div>
                        <div className={'article-img'}>
                            <img
                                src={this.state.article.image}
                                height={'100%'}
                                width={'100%'}
                            />
                        </div>
                        <div className={'article-text'}>
                            <p>
                                {this.state.article.articleText}
                            </p>
                        </div>
                        <div className={'article-comment-section'}>
                            {commentButtonSection}
                            <div className={'article-comments'}>
                                <div className={'c article-comment-header'}><label>Comments</label></div>
                                { this.state.comments &&
                                    this.state.comments.map(c => (
                                        <div className={'article-comment'}>
                                            <div className={'w75'}>
                                                {c.text}
                                            </div>
                                            <div className={'w25'} style={{fontSize: '10px'}}>
                                                {c.userId} - {this.showCommentDate(c.time)}
                                            </div>
                                        </div>)) }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    showCommentDate(str) {
        const date = dayjs(str);
        return date.format('DD-MM-YYYY HH:mm');
    }

    postComment = () => {
        let $comment = document.querySelector('#comment');
        let userId = this.state.userId;
        let articleId = this.state.articleId;
        ApiService.instance().postComment($comment.value, userId, articleId).then(result => {
            console.log('Article-PostComment: ', result);
            if(result) {
                const comments = [result, ...this.state.comments];
                this.setState({comments: comments});
            }
        }).catch(err => {
            console.log(err)
        });
    }
}

export default withRouter(Article);