import logo from './logo.svg';
import './App.css';
import Banner from "./components/banner/banner";
import Home from "./components/home/home";
import {
    BrowserRouter,
    BrowserRouter as Router,
    Route,
    Routes, Switch
} from "react-router-dom";
import Post from "./components/post/post";
import Article from "./components/article/article";
import ArticleCreator from "./components/article-creator/articleCreator";
import Login from "./components/login/login";

function App() {

  return (
    <div className="App">
        <Banner></Banner>
        {/*//https://stackoverflow.com/questions/55153873/warning-each-child-in-a-list-should-have-a-unique-key-prop*/}
        <div className={'w100'} style={{minHeight: '1000px'}}>
            <Router>
                <Routes>
                    <Route
                        path='/'
                        element={<Home />}>
                    </Route>
                    <Route
                        path='/home'
                        element={<Home />}>
                    </Route>
                    <Route
                        path='/article/create'
                        element={<ArticleCreator />}>
                    </Route>
                    <Route
                        path='/article/:id'
                        element={<Article />}>
                    </Route>
                    <Route
                        path='/login'
                        element={
                            <Login type={{isLogin: true}}>

                            </Login>}>
                    </Route>
                    <Route
                        path='/register'
                        element={
                            <Login type={{isLogin: false}}>
                            </Login>}>
                    </Route>
                </Routes>
            </Router>
        </div>
        <div className={'footer'}>
            <p style={{color: 'white'}}>
                @Author: zoidberg.ftm
                admin@shotbrawlers.com
            </p>
        </div>
    </div>
  );
}

export default App;
