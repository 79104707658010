import ApiService from "./apiService";
import Constants from "../util/constants";
import {_} from 'underscore';

class SessionService {
    static _instance;
    constructor() {
        this.sessionId = ''
        this.apiService = new ApiService();
    }

    static instance() {
        if(this._instance) return this._instance;

        this._instance = new SessionService();
        return this._instance;
    }

    isLoggedIn() {
        const sessionIdStorage = localStorage.getItem(Constants.SESSION_KEY);
        return sessionIdStorage && sessionIdStorage !== '' && this.apiService.authenticate(sessionIdStorage);
    }

    async login(username, password) {
        const result = await this.apiService.login(username, password);
        if(result && result.session) {
            this.setSession(username, result.session);
            return true;
        }
        return false;
    }

    async register(username, email, password) {
        const result = await this.apiService.register(username, email, password);
        return result && result.success;
    }

    async logout() {
        const username = localStorage.getItem(Constants.USER_KEY);
        const session = localStorage.getItem(Constants.SESSION_KEY);
        const result = await this.apiService.logout(username, session);
        this.clearSession();
        return false;
    }

    clearSession() {
        localStorage.setItem(Constants.SESSION_KEY, '');
        localStorage.setItem(Constants.USER_KEY, '');
    }

    setSession(username, session) {
        localStorage.setItem(Constants.SESSION_KEY, session);
        localStorage.setItem(Constants.USER_KEY, username);
    }
}

export default SessionService;