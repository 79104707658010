import {React, Component} from "react";
import {withRouter} from "../../util/withRouter";
import SessionService from "../../service/sessionService";
import {redirect} from "../../util/util";
class Login extends Component {
    constructor(props) {
        super(props);
        console.log('Login-Constructor Props: ', props)
        this.state = {
            isLogin: props.type.isLogin,
            sessionService: SessionService.instance(),
        }
    }

    componentDidMount() {
    }

    render() {
        const showEmail = () => {
            if(!this.state.isLogin) {
                return (<div className={'c login-row'}>
                    <div className={'w50'}>
                        <label>Email</label>
                    </div>
                    <div className={'w50'}>
                        <input type={'text'} id={'email'}/>
                    </div>
                </div>)
            }
        }
        return (
            <div className={'w100 login'}>
                <div className={'login-container'}>
                    <div className={'c login-header login-row'}>
                        <label>Login</label>
                    </div>
                    <div className={'c login-row'}>
                        <div className={'w50'}>
                            <label>Username</label>
                        </div>
                        <div className={'w50'}>
                            <input type={'text'} id={'username'} placeholder={!this.state.isLogin ? '': 'username or email...'}/>
                        </div>
                    </div>
                    {showEmail()}
                    <div className={'c login-row'}>
                        <div className={'w50'}>
                            <label>Password</label>
                        </div>
                        <div className={'w50'}>
                            <input type={'password'} id={'password'}/>
                        </div>
                    </div>
                    <div className={'c login-footer'}>
                            <button id={'login-btn'} onClick={this.state.isLogin ? this.login.bind(this) : this.register.bind(this)}>{this.state.isLogin ? 'Login' : 'Register'}</button>
                    </div>
                </div>

            </div>
        )
    }

    login() {
        const username = document.querySelector('input#username').value;
        const password = document.querySelector('input#password').value;
        this.state.sessionService.login(username, password).then(result => {
            if(result) {
                alert('Login Successful!');
                setTimeout(() => redirect('/home'), 500);
            } else {
                alert('Login Failed!')
            }
        }).catch(err => {
            console.log('SessionService-error: ', err);
        });
    }

    register() {
        const username = document.querySelector('input#username').value;
        const password = document.querySelector('input#password').value;
        const email = document.querySelector('input#email').value;
        this.state.sessionService.register(username, email, password).then(result => {
            if(result) {
                alert('Registration Successful!');
                setTimeout(() => redirect('/home'), 500);
            }
            else alert('Registration Failed!');
            console.log('SessionService-register: ', result);
        }).catch(err => {
            console.log('SessionService-register-error: ', err);
        });
    }

}

export default withRouter(Login)