//Todo: Move to model
export class PostModel {
    postId;
    article;
    postComments;

    constructor(article, comments) {
        this.article = article;
        this.postId = -1;
        this.postComments = comments
    }

}