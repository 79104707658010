import React from "react";
import {Link} from "react-router-dom";
import Post from "../post/post";
import MockApi from "../../mock/mockApi";
import {withRouter} from "../../util/withRouter";
import ApiService from "../../service/apiService";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 1,
            scrollY: 0,
            posts: []
        }
    }
    componentDidMount() {
        window.onscroll = this.scrollLoad.bind(this);
        this.loadPosts();
        this.setState({scrollY: (window.screen.height - 100)})
    }

    render() {
        let posts = [];
        for(let i in this.state.posts) {
            const post = {...this.state.posts[i]};
            posts.push(
                <Post key={post.postId}>{post}</Post>
            );
        }
        return (
            <div className={'w100 home'}>
                <div className={'home-container'}>
                    {posts}
                </div>
            </div>
        )
    }

    loadPosts() {
        let count = 3;
        let index = this.state.pageIndex;
        let posts = this.state.posts;

        ApiService.instance().getArticles(this.state.pageIndex, count)
        .then(result => {
            posts = [...posts, ...result];
            index += count;
            this.setState({ posts: posts, pageIndex: index})
        }).catch(err => {
            console.log('API-ERROR: ', err);
        });

    }

    scrollLoad(e) {
        const $home = document.querySelector('div.home');
        const component = this;
        if($home) {
            setTimeout(() => {
                //https://stackoverflow.com/questions/53158796/get-scroll-position-with-reactjs
                const winScroll =
                    document.body.scrollTop || document.documentElement.scrollTop

                const height =
                    document.documentElement.scrollHeight -
                    document.documentElement.clientHeight

                const scrolled = 100 * (winScroll / height)
                console.log('scrolled: ', scrolled)
                // if(scrolled > 90) component.loadPosts();
            }, 500);
        } else {
            window.onscroll = null;
        }
    }
}

export default withRouter(Home);