import axios from "axios";
export default class ApiService {
    static _instance;
    constructor() {
        this.apiBase = "https://shotbrawlers.net";
        this.routes = {
            GET_ARTICLES:'/api/v1/article/',
            CREATE_ARTICLE:'/api/v1/article/create',
            CREATE_COMMENT:'/api/v1/article/comment',
            LOGIN:'/api/v1/account/login',
            REGISTER:'/api/v1/account/register',
            AUTHENTICTE:'/api/v1/account/authenticate',
            LOGOUT:'/api/v1/account/logout',
        };
        this.headersConfig = {
            "content-type": "application/json",
            "Accept": "application/json"
        }
    }

    static instance() {
        if(this._instance) return this._instance;

        this._instance = new ApiService();
        return this._instance;
    }
    async getArticles(index, count) {
        //Todo: Make sure actual api call handles the paging correctly
        let posts = [];
        return await axios({
                method: 'get',
                url: `${this.apiBase}${this.routes.GET_ARTICLES}`,
                headers: this.headersConfig,
                responseType: 'application/json',
        })
        .then(response => {
            if(!response.error && response.data) {
                const parseData = JSON.parse(response.data);
                return parseData.result;
            }

            return posts;
        })
        .catch(err => {
            console.log('API-ERROR: ', err);
            return posts;
        });
    }


    postComment = (comment, userId, articleId) => {
        return axios({
            method: 'post',
            url: `${this.apiBase}${this.routes.CREATE_COMMENT}/`,
            data: {
                articleId: articleId,
                comment: comment,
                userId: userId
            },
        })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.log('API-ERROR: ', err);
                return false;
            });
    }

    getArticle(postId) {
        //Todo: Make sure actual api call handles the paging correctly
        let posts = [];
        return axios({
            method: 'get',
            url: `${this.apiBase}${this.routes.GET_ARTICLES}/${postId}`,
            headers: this.headersConfig
        })
            .then(response => {
                posts = !response.error && response.data && response.data.result
                    ? response.data.result
                    : posts;
                return posts;
            })
            .catch(err => {
                console.log('API-ERROR: ', err);
                return posts;
            });
    }

    async createArticle(post) {
        return await axios({
            method: 'post',
            url: `${this.apiBase}${this.routes.CREATE_ARTICLE}`,
            data: {
                post:post
            },
            headers: this.headersConfig
        })
        .then(result => {
            return result && result.data;
        }).catch(err => {
                console.log('API-ERROR: ', err);
                return false;
        });
    }

    async authenticate() {
        return await axios({

        }).catch(err => {
            console.log('API-ERROR: ', err);
            return false;
        })
    }

    async login(username, password) {
        return await axios({
            method: 'post',
            url: `${this.apiBase}${this.routes.LOGIN}`,
            data: {
                username: username,
                password: password
            },
            headers: this.headersConfig
        }).then(result => {
            return (result && result.data && result.data.result) ? result.data.result : ''
        }).catch(err => {
            console.log('API-ERROR: ', err);
            return '';
        });
    }

    async register(username, email, password) {
        return await axios({
            method: 'post',
            url: `${this.apiBase}${this.routes.REGISTER}`,
            data: {
                username: username,
                password: password,
                email: email
            },
            headers: this.headersConfig

        }).then(result => {
            return (result && result.data && result.data.result) ? result.data.result : ''
        }).catch(err => {
            console.log('API-ERROR: ', err);
            return false;
        })
    }

    async logout(username, session) {
        return await axios({
            method: 'post',
            url: `${this.apiBase}${this.routes.LOGOUT}`,
            data: {
                username:username,
                sessionId:session
            },
            headers: this.headersConfig
        }).then(result => {
            return (result && result.data && result.data.result) ? result.data.result : false
        }).catch(err => {
            console.log('API-ERROR: ', err);
            return false;
        });
    }
}