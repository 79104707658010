import {React, Component} from "react";
import {withRouter} from "../../util/withRouter";
import ApiService from "../../service/apiService";
import {PostModel} from "../../model/postModel";
import ArticleModel from "../../model/articleModel";

class ArticleCreator extends Component {
    constructor(props) {
        super(props);
        this.apiService = new ApiService();
    }

    render() {
        return (
            <div className={'article-creator w100'}>
                <div className={'article-creator-container'}>
                    <div className={'w100 article-creator-row'}>
                        <label>Article Creator</label>
                    </div>
                    <div className={'w100 article-creator-row'}>
                        <div className={'w25'}>
                            <label>Title: </label>
                        </div>
                        <div className={'w75'}>
                            <input className={'article-text-input'}  type={'text'} id={'article-title'}/>
                        </div>
                    </div>
                    <div className={'w100 article-creator-row'}>
                        <div className={'w25'}>
                            <label>Image</label>
                        </div>
                        <div className={'w75'}>
                            <input type={'text'} id={'article-img'} className={'article-text-input'}/>
                        </div>
                    </div>
                    <div className={'w100 article-creator-row'}>
                        <div className={'w25'}>
                            <label>PreText: </label>
                        </div>
                        <div className={'w75'}>
                            <input type={'text'} id={'article-pretext'} className={'article-text-input'}/>
                        </div>
                    </div>
                    <div className={'w100 article-creator-textarea-row'}>
                        <div className={'w25'}>
                            <label>Article Text</label>
                        </div>
                        <div className={'w75'}>
                            <textarea className={'article-text-area'} id={'article-text'}/>
                        </div>
                    </div>
                    <div className={'w100 article-creator-row'}>
                        <div className={'w100'}>
                            <button id={'submitArticle'}  onClick={this.createArticle.bind(this)}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createArticle() {
        const title = document.querySelector('#article-title').value;
        const img = document.querySelector('#article-img').value;
        const preText = document.querySelector('#article-pretext').value;
        const articleText = document.querySelector('#article-text').value;
        const post = new PostModel(new ArticleModel(title, img, preText, articleText));
        this.apiService.createArticle(post)
            .then(res => alert('Successfully created article'))
            .catch(err => alert('Failed to create article... \n Try again...'));
    }
}

export default withRouter(ArticleCreator);