export class MockDb {
    posts
    constructor() {
        this.posts = [
            {
                postName:"Article 1",
                postId: "1",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[
                    "OMG that fight was so sick!",
                    "OMG that fight was so sick!",
                    "OMG that fight was so sick!",
                    "OMG that fight was so trash!",
                    "OMG that fight was so sick!",
                ]

            },
            {
                postName:"Article 2",
                postId: "2",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum"

            },
            {
                postName:"Article 3",
                postId: "3",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 4",
                postId: "4",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 5",
                postId: "5",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 6",
                postId: "6",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 7",
                postId: "7",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 8",
                postId: "8",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 9",
                postId: "9",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 10",
                postId: "10",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[]

            },
            {
                postName:"Article 11",
                postId: "11",
                postImg: "https://mmanuts.com/wp-content/uploads/2014/10/USATSI_7274661.jpg",
                postPreText: "",
                postArticleText: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum\n" +
                    "                        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem\n" +
                    "                        Ipsum Lorem Ipsum Lorem Ipsum",
                postComments:[],

            },
        ]
    }
}

