export function redirect(endpoint) {
    // return new Response("",{
    //     status: 200,
    //     headers: { Location: endpoint}
    // });
    window.location = `${window.origin}${endpoint}`;
}

export function isMobile() {
    const MOBILE_SCREEN_MAX = 812;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // Check if the screen width is greater than the specified number of pixels
    return screenWidth <= MOBILE_SCREEN_MAX;
}