import {MockDb, Post} from "./mockDb";

class MockApi {
    constructor() {
        this.mockDb = new MockDb();
    }

    getArticles(index, count) {
        //Todo: Make sure actual api call handles the paging correctly
        let posts = []
        if(this.mockDb.posts.length <= index) return posts;

        while(count > 0) {
            posts.push(this.mockDb.posts[index - 1]);
            index++;
            count--;
        }
        return posts;
    }


    postComment = (comment, user, articleId) => {
        for(let i = 0; i < this.mockDb.posts.length; i++) {
            if(this.mockDb.posts[i].postId === articleId) {
                this.mockDb.posts[i].postComments.push(comment);
                i = this.mockDb.posts.length;
            }
        }
    }

    getArticle(postId) {
        const post = this.mockDb.posts.filter(p => p.postId === `${postId}`);
        return post.length === 1 ? post[0] : null;
    }
}

export default MockApi;